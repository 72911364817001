import * as React from "react";
import { Link } from "gatsby";

import { Layout } from "../components/layout";
import { Seo } from "../components/seo";

import SadFaceIcon from "../images/face-sad-cry.svg";

import * as classes from "./404.module.css";

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo isHome noIndex />
      <div className={classes.root}>
        <SadFaceIcon className={classes.icon} />
        <p className={classes.text}>Nothing found here</p>
        <Link to="/" className={classes.link}>
          Go home
        </Link>
        .
      </div>
    </Layout>
  );
};

export default NotFoundPage;
